import React from 'react';
import avatar from '../images/avatar.jpg';
import FooterH from './FooterH';

function Header() {
  return (
    <header id="header">
      <div className="inner">
        <img className="image avatar" src={avatar} alt="avatar" />
        <h1>
          <strong>John Chaing</strong>
          <br />
          <p>Full Stack Web Developer</p>
          <br />
        </h1>
      </div>
      <FooterH />
    </header>
  );
}

export default Header;
