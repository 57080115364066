import React from 'react';
import About from './About';
import RecentWork from './RecentWork';
import Contact from './Contact'

function Main() {
  return (
    <div id="main">
      <About />
      <RecentWork />
      <Contact />
    </div>
  );
}

export default Main;
