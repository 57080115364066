import React from 'react';
import resume from '../resume/John_Chaing_Resume.pdf';

function About() {
  return (
    <section id="one">
      <header className="major">
        <h2>
          About me
          <br />
        </h2>
      </header>
      <p>
        <strong>
          <p>
            I am a Full-Stack Web Developer with a preference for the Front-end
            who loves to help others and solve problems. With a wide background
            in Information Technology, I am highly adaptable, resourceful and
            result-driven with excellent soft-skills. I'm ecstatic to work with
            a team that can make the world a better place.
          </p>
        </strong>
      </p>
      <h3>
        Skills
        <br />
      </h3>
      <p>
        <strong>
          <p>
            HTML/CSS | JavaScript | Typescript | ReactJS | NextJS | Redux |
            NodeJS | Vercel | PostgreSQL | MongoDB | Tailwind | CMS
          </p>
        </strong>
      </p>
      <ul className="actions">
        <li>
          <a href={resume} target="_blank" rel="noreferrer" className="button">
            Resume
          </a>
        </li>
      </ul>
    </section>
  )
}

export default About;
