import React from 'react';
// import oneImg from '../images/thumbs/01.png';
// import twoImg from '../images/thumbs/02.png';
// import threeImg from '../images/thumbs/03.png';
// import fourImg from '../images/thumbs/04.png';
// import fiveImg from '../images/thumbs/05.png';
// import sixImg from '../images/thumbs/06.png';
import plza from '../images/thumbs/plza.png';
// import conway from '../images/thumbs/conway.png';
import remodelmate from '../images/thumbs/remodelmate.png'

function RecentWork() {
  return (
    <section id="two">
      <h2>Projects</h2>
      <div className="row">
        <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://remodelmate-website.vercel.app/"
            target="_blank"
            rel="noreferrer"
            className="image fit thumb"
          >
            <img src={remodelmate} height="" alt="Plza screenshot" />
          </a>
          <h3>Remodelmate.com</h3>
          <p>
            Effortless Bathroom Renovations - Design your dream bathroom in
            minutes - no material sourcing, no hidden fees, just seamless
            renovations
          </p>
          <br />
          <p>
            <a
              href="https://remodelmate-website.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
          </p>
          <p>
            <strong>
              Typescript | ReactJS | NextJS | Vercel | Tailwind CSS | MongoDB |
              Storyblok CMS
            </strong>
          </p>
        </article>

        <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://plza.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="image fit thumb"
          >
            <img src={plza} height="" alt="Plza screenshot" />
          </a>
          <h3>Plza</h3>
          <p>
            A social-media platform designed to connect pizza lovers with pizza
            shops, where they can find & rate pizza and organize pizza-related
            social gatherings and events.
          </p>
          <br />
          <p>
            <a
              href="https://github.com/Lambda-School-Labs/lets-get-pizza-fe"
              target="_blank"
              rel="noreferrer"
            >
              Code
            </a>
          </p>
          <p>
            <strong>
              HTML/CSS | Semantic UI | JavaScript | React | Node | PostgreSQL |
              Foursquare API
            </strong>
          </p>
        </article>

        {/* <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://app-conways-game-of-life.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="image fit thumb"
          >
            <img
              src={conway}
              height=""
              alt="Conway's Game of Life screenshot"
            />
          </a>
          <h3>Conway's Game of Life</h3>
          <p>
            Conway's Game of Life simulates the birth and death of cells on a
            rectangular grid.
          </p>
          <p>
            <br />
            <a
              href="https://github.com/jchaing/conways-game-of-life"
              target="_blank"
              rel="noreferrer"
            >
              Code
            </a>
          </p>

          <p>
            <strong>HTML/CSS | JavaScript | React | Bootstrap</strong>
          </p>
        </article>

        <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://wunderlist2-bw-august-2019.github.io/landing-page/"
            target="_blank"
            rel="noreferrer"
            className="image fit thumb"
          >
            <img
              src={oneImg}
              height=""
              alt="WunderList 2.0 Landing Page screenshot"
            />
          </a>
          <h3>WunderList 2.0</h3>
          <p>A mockup for a Web Design marketing landing page</p>
          <br />
          <p>
            <a
              href="https://github.com/wunderlist2-bw-august-2019/landing-page"
              target="_blank"
              rel="noreferrer"
            >
              Code
            </a>
          </p>
          <p>
            <strong>HTML/CSS | Bootstrap | SVG</strong>
          </p>
        </article>

        <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://jchaing.github.io/nasa-photo-of-the-day/"
            target="_blank"
            rel="noreferrer"
            className="image fit thumb"
          >
            <img src={twoImg} height="" alt="Nasa's POTD screenshot" />
          </a>
          <h3>NASA's Astronomy Photo of the Day</h3>
          <p>
            Displays NASA's APOD. Built with React.js, Reactstrap, NASA's API
          </p>
        </article>

        <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://reducer-todo.jchaing.now.sh/"
            target="_blank"
            rel="noreferrer"
            className="image fit thumb"
          >
            <img src={sixImg} alt="Reducer Todo List screenshot" />
          </a>
          <h3>Reducer Todo</h3>
          <p>Todo App Built with React Reducers</p>
        </article> */}

        {/* <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://news-component.netlify.com/"
            className="image fit thumb"
          >
            <img src={threeImg} height="" alt="" />
          </a>
          <h3>Lambda School Newsfeed</h3>
          <p>Newsfeed built with Javascript and LESS</p>
        </article> */}

        {/* <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://github-user-card.netlify.com/"
            className="image fit thumb"
          >
            <img src={fourImg} height="" alt="" />
          </a>
          <h3>Github User Card</h3>
          <p>Created Github User Card with Javascript Components</p>
        </article> */}

        {/* <article className="col-6 col-12-xsmall work-item">
          <a
            href="https://codepen.io/jchaing/full/wLNNXJ"
            className="image fit thumb"
          >
            <img src={fiveImg} height="" alt="" />
          </a>
          <h3>Sweet Eats Bakery</h3>
          <p>Bakery site developed on Codepen</p>
        </article> */}
      </div>
      <ul className="actions">
        <li>
          <a
            href="https://www.github.com/jchaing"
            target="_blank"
            rel="noreferrer"
            className="button"
          >
            Github
          </a>
        </li>
      </ul>
    </section>
  )
}

export default RecentWork;
