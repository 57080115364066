import React from 'react';

function Footer() {
  return (
    <footer id="footer">
      <div className="inner">
        <ul className="icons">
          <li>
            <a href="https://linkedin.com/in/john-chaing" className="icon">
              <i className="fa-lg fab fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a href="https://github.com/jchaing" className="icon">
              <i className="fa-lg fab fa-github"></i>
            </a>
          </li>
          <li>
            <a href="mailto:john@engi.dev" className="icon">
              <i className="fa-lg fas fa-envelope"></i>
            </a>
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy; John Chaing</li>
          <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer;
