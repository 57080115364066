import React from 'react';

function Contact() {
  return (
    <section id="three">
      <h2>Get In Touch</h2>
      <div className="row">
        <div className="col-12 col-12-small">
          <form name="contact" id="myform" method="post" action="#">
            <input type="hidden" name="form-name" value="contact" />
            <div className="row gtr-uniform gtr-50">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="name" id="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-xsmall">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              </div>
              <div className="col-12">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Message"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </form>
          <ul className="actions">
            <li>
              <input type="submit" form="myform" value="Send Message" />
            </li>
          </ul>
        </div>
        {/* <div className="col-4 col-12-small">
          <ul className="labeled-icons">
            <li>
              <h3 className="icon">
                <i className="fa-sm fas fa-home"></i>
              </h3>
              Torrance, CA
              <br />
              United States
            </li>
            <li>
              <h3 className="icon">
                <i className="fa-sm fas fa-envelope"></i>
              </h3>
              <a href="mailto:john@spinningplates.org">
                john@spinningplates.org
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </section>
  );
}

export default Contact
